import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Last Day of Deload Week`}</em></p>
    <p>{`Skill Practice: 10:00 EMOM of:`}</p>
    <p>{`1-Turkish Get Up, right arm`}</p>
    <p>{`1-Turkish Get Up, left arm`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`CrossFit Open WOD 17.2`}</strong></p>
    <p>{`12:00 AMRAP of`}</p>
    <p>{`2 rounds of:`}</p>
    <p>{`50ft DB Walking Lunges (DB’s held in front rack position)(Rx=50/35’s,
Scaled=35/20’s)`}</p>
    <p>{`16 T2B (scaled=hanging knee raise)`}</p>
    <p>{`8 DB Power Cleans (50/35’s)`}</p>
    <p>{`then, 2 rounds of:`}</p>
    <p>{`50ft DB Walking Lunges`}</p>
    <p>{`16 Bar Muscle Ups (scaled=chin over bar pullups)`}</p>
    <p>{`8 DB Power Cleans`}</p>
    <p>{`etc, alernating T2B & Bar Muscle Ups every 2 rounds.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The CrossFit Open starts February 22nd!  Register now at
Games.CrossFit.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      